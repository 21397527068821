import { useQuery } from "@apollo/client"
import styled from "@emotion/styled"
import React from "react"
import { useNavigate, useOutletContext } from "react-router-dom"
import { logEvent } from "../../../analytics/analytics"
import {
  CreateContributionInput,
  FiveTwoNineAccount,
} from "../../../generated/graphql"
import {
  CREATE_CONTRIBUTION,
  GET_CONTRIBUTION_PROCESSING_FEE,
  GET_VIEWER,
} from "../../../graphql/queries"
import { Avatar } from "../../../ui/Avatar"
import Button from "../../../ui/Button"
import {
  Card,
  CardBody,
  CardListItem,
  CardListItemLabel,
  CardListItemValue,
} from "../../../ui/Card"
import { InfoBlock } from "../../../ui/InfoBlock"
import { Spacer } from "../../../ui/Layout"
import { centsToDollars } from "../../../util/currency"
import { displayValidationErrorAlert } from "../../../util/errorAlerts"
import {
  contributionPaymentMethodPath,
  fiveTwoNineContributionSuccessPath,
} from "../../../util/paths"
import { useSafeMutation } from "../../../util/useSafeMutation"
import {
  ContributionHeaderDiv,
  ContributionHeaderTitleDiv,
} from "./ContributionForm"

export const ContributionConfirmation = ({
  contribution,
  clearContribution,
}: {
  contribution: CreateContributionInput
  clearContribution: () => void
}) => {
  const { fiveTwoNineAccount, fiveTwoNineAccountSlug } = useOutletContext<{
    fiveTwoNineAccount: FiveTwoNineAccount
    fiveTwoNineAccountSlug: string
  }>()
  const navigate = useNavigate()
  const { data: processingFee } = useQuery(GET_CONTRIBUTION_PROCESSING_FEE, {
    variables: {
      contributionAmountCents: contribution.amountCents,
      recipientId: fiveTwoNineAccount.user?.id,
    },
  })

  const processingFeeCents = processingFee?.processingFee.feeAmountCents ?? 0
  const [execCreateContribution, { loading }] = useSafeMutation(
    CREATE_CONTRIBUTION,
    {
      refetchQueries: [{ query: GET_VIEWER }],
    },
  )

  const onPressConfirm = async () => {
    await execCreateContribution({
      variables: {
        input: contribution,
      },
      onCompleted: (data) => {
        if (displayValidationErrorAlert(data.createContribution.errors)) {
          return
        }
        logEvent("Creates Contribution", {
          fiveTwoNineAccount: fiveTwoNineAccount.id,
          contributionStatus: contribution.visibility.toLowerCase(),
          contributionAmount: centsToDollars(contribution.amountCents),
          processingFee: centsToDollars(processingFeeCents),
          total: centsToDollars(contribution.amountCents + processingFeeCents),
        })

        navigate(
          `${fiveTwoNineContributionSuccessPath({
            fiveTwoNineAccountSlug,
          })}?amountCents=${contribution.amountCents}`,
        )
      },
      onError: (e) => {
        if (
          e.graphQLErrors.find(
            (err) => err.message === "Bank account is invalid",
          )
        ) {
          navigate(
            contributionPaymentMethodPath({
              fiveTwoNineAccountSlug,
            }),
            { replace: true },
          )

          return
        }
        alert("Error creating contribution, please contact support")
      },
    })
  }

  return (
    <ContainerView>
      <ContributionHeaderDiv>
        <CancelLink type="button" onClick={clearContribution}>
          Cancel
        </CancelLink>
        <ContributionHeaderTitleDiv>Confirm Payment</ContributionHeaderTitleDiv>
      </ContributionHeaderDiv>

      <Card>
        <CardBody>
          <CardHeaderView>
            <AvatarContainerView>
              <Avatar
                diameter={100}
                fontSize={38}
                firstName={fiveTwoNineAccount.beneficiaryFirstName || undefined}
                imageSrc={fiveTwoNineAccount.beneficiaryPhotoUrl || undefined}
              />
            </AvatarContainerView>
            <NameText>{fiveTwoNineAccount.beneficiaryFirstName}</NameText>
            {!!contribution.message && (
              <MessageText>{contribution.message}</MessageText>
            )}
          </CardHeaderView>
          <CardListItem>
            <CardListItemLabel>Contribution Status</CardListItemLabel>
            <CardListItemValue style={{ textTransform: "capitalize" }}>
              {contribution.visibility.toLowerCase()}
            </CardListItemValue>
          </CardListItem>
          <CardListItem>
            <CardListItemLabel>Contribution Amount</CardListItemLabel>
            <CardListItemValue style={{ textTransform: "capitalize" }}>
              {centsToDollars(contribution.amountCents)}
            </CardListItemValue>
          </CardListItem>
          <CardListItem>
            {processingFeeCents > 0 ? (
              <>
                <CardListItemLabel>Processing Fee</CardListItemLabel>
                <InfoBlock>
                  Hadley is a public benefit corporation (keep black font, but
                  change company to corp) and Registered Investment Adviser with
                  the SEC. We do not charge the public advisory fees or
                  subscription fees, because we exist to make education
                  accessible and affordable for everyone. We charge a nominal
                  processing fee to cover our own costs helping our users
                  seamlessly transfer money from their bank accounts to their
                  own or others' 529 accounts. We keep our processing fee as low
                  as possible.
                </InfoBlock>
              </>
            ) : (
              <div>
                <CardListItemLabel>Processing Fee</CardListItemLabel>
                <InfoBlock text="Why is this fee waived?">
                  <div>
                    Congrats! This processing fee is waived because either the
                    contributor or recipient (or both!) of this contribution
                    gets 529 Benefits with Hadley at their workplace. Want
                    Hadley as an Employee Benefit? Email{" "}
                    <a href="mailto:Support@GoHadley.com">
                      Support@GoHadley.com
                    </a>
                    .
                  </div>
                </InfoBlock>
              </div>
            )}
            <CardListItemValue style={{ textTransform: "capitalize" }}>
              {centsToDollars(processingFeeCents)}
            </CardListItemValue>
          </CardListItem>
          <CardListItem>
            <CardListItemLabelLg>Grand Total</CardListItemLabelLg>
            <CardListItemValueLg style={{ textTransform: "capitalize" }}>
              {centsToDollars(contribution.amountCents + processingFeeCents)}
            </CardListItemValueLg>
          </CardListItem>
        </CardBody>
      </Card>
      <Spacer size="lg" />

      <Button onClick={onPressConfirm} className="btn-block" disabled={loading}>
        <ContributeButtonText>Confirm Contribution</ContributeButtonText>
      </Button>

      <InfoText>
        Once you click “Confirm Contribution,” you will be unable to cancel
        payment for this contribution.
      </InfoText>
      <DisclaimerText>
        <DisclaimerStartText>DISCLAIMER </DisclaimerStartText>
        You are about to make a contribution to an investment account.
        Understand that all investments have a potential risk of loss, including
        the loss of the amount originally invested. 529 contributions are
        non-refundable. You will incur a processing fee of{" "}
        {centsToDollars(processingFeeCents)} associated with this contribution.
        This fee is collected by Hadley on behalf of our third-party payment
        processor.
      </DisclaimerText>
    </ContainerView>
  )
}

const CardListItemLabelLg = styled(CardListItemLabel)(({ theme }) => ({
  paddingTop: theme.margins.xs,
  paddingBottm: theme.margins.xs,
  color: theme.colors.textPrimary,
}))

const CardListItemValueLg = styled(CardListItemValue)(({ theme }) => ({
  color: theme.colors.textPrimary,
  fontSize: theme.fontSizes.xl,
  fontWeight: "600",
}))

const ContainerView = styled.div(({ theme }) => ({
  flex: 1,
}))

const NameText = styled.div(({ theme }) => ({
  color: theme.colors.textPrimary,
  fontSize: theme.fontSizes.md,
  fontWeight: "600",
}))

const MessageText = styled.div(({ theme }) => ({
  color: theme.colors.textPrimary,
  fontSize: theme.fontSizes.sm,
  marginTop: theme.margins.xxs,
}))

const AvatarContainerView = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: theme.margins.xxs,
  marginBottom: theme.margins.md,
}))

const CardHeaderView = styled.div(({ theme }) => ({
  padding: theme.margins.md,
}))

const ContributeButtonText = styled.div(({ theme }) => ({
  color: theme.colors.white,
  fontWeight: "600",
}))

const InfoText = styled.div(({ theme }) => ({
  color: theme.colors.textLight,
  fontSize: theme.fontSizes.xs,
  textAlign: "center",
  marginTop: theme.margins.lg,
  marginBottom: theme.margins.xl,
}))

const DisclaimerText = styled.div(({ theme }) => ({
  marginTop: "auto",
  marginBottom: theme.margins.xxl,
  color: theme.colors.textLight,
  fontSize: theme.fontSizes.xxs,
}))

const DisclaimerStartText = styled.div(({ theme }) => ({
  color: theme.colors.black,
}))

const CancelLink = styled.a(({ theme }) => ({
  fontSize: theme.fontSizes.xs,
  color: theme.colors.textLight,
  textDecoration: "none",
  position: "absolute",
  left: 0,
  top: 2,
}))
