import { useQuery } from "@apollo/client"
import styled from "@emotion/styled"

import { useFormik } from "formik"
import React, { useCallback, useEffect, useState } from "react"

import { GetPlaidAddressQuery } from "../../generated/graphql"
import {
  GET_BANK_ACCOUNT,
  GET_PLAID_IDENTITY,
  REMOVE_BANK_ACCOUNT,
} from "../../graphql/queries"
import { Button } from "../../ui/Button"
import {
  Card,
  CardListItem,
  CardListItemLabel,
  CardListItemValue,
} from "../../ui/Card"
import { LoadingScreen } from "../../ui/LoadingScreen"
import { TitledParagrah } from "../../ui/TitledParagraph"
import { displayErrorAlert } from "../../util/errorAlerts"
import { useBankAccount } from "../../util/useBankAccount"
import { useSafeMutation } from "../../util/useSafeMutation"
import useShouldRequireAddress from "../../util/useShouldRequireAddress"
import { useUpdateUser } from "../../util/useUpdateUser"
import { AddBankAccount } from "./AddBankAccount"
import { AddBillingAddress } from "./AddBillingAddress"

export const PaymentMethod = ({
  onValidBankAccount,
}: {
  onValidBankAccount?: () => void
}) => {
  const { bankAccount, loading: bankLoading, error } = useBankAccount()
  const execUpdateUser = useUpdateUser()
  const [execRemoveBankAccount] = useSafeMutation(REMOVE_BANK_ACCOUNT, {
    onError: () => {
      alert(
        "Error removing bank account\nPlease refresh the page and try again",
      )
    },
    refetchQueries: [GET_BANK_ACCOUNT],
  })

  const [showEditAddress, setShowEditAddress] = useState(false)
  const {
    viewerIdentity,
    shouldRequireAddress,
    setRequireAddress,
    addressLoading,
  } = useShouldRequireAddress()

  const { data: plaidUser, loading: plaidLoading } =
    useQuery<GetPlaidAddressQuery>(GET_PLAID_IDENTITY, {
      skip: !bankAccount?.id,
    })

  const initialValues = viewerIdentity?.viewer
    ? { ...viewerIdentity?.viewer }
    : {}

  if (
    !(
      viewerIdentity?.viewer?.address?.city &&
      viewerIdentity.viewer.address.state &&
      viewerIdentity.viewer.address.streetAddress1
    )
  ) {
    initialValues.address = plaidUser?.plaidIdentity?.address
  }
  if (
    !(
      viewerIdentity?.viewer?.billingFirstName &&
      viewerIdentity.viewer.billingLastName
    )
  ) {
    initialValues.billingFirstName = plaidUser?.plaidIdentity?.firstName
    initialValues.billingLastName = plaidUser?.plaidIdentity?.lastName
  }

  const { values, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      editViewer: initialValues,
    },
    enableReinitialize: true,
    onSubmit: async ({ editViewer }) => {
      if (!editViewer) return

      const { errors } = await execUpdateUser({
        variables: {
          input: {
            billingFirstName: editViewer.billingFirstName,
            billingLastName: editViewer.billingLastName,
            address: {
              stateId: editViewer.address?.state?.id,
              streetAddress1: editViewer.address?.streetAddress1,
              streetAddress2: editViewer.address?.streetAddress2,
              city: editViewer.address?.city,
              zip: editViewer.address?.zip,
            },
          },
        },
      })

      if (displayErrorAlert(errors)) return
      setRequireAddress(false)
    },
  })

  const bankAddress = values.editViewer?.address

  /**
   * If user connects bank account and we get no address back from Plaid, force user into Edit form
   */
  const forceEditAddress =
    shouldRequireAddress &&
    !addressLoading &&
    !plaidLoading &&
    !(
      bankAddress?.city &&
      bankAddress?.streetAddress1 &&
      bankAddress?.state &&
      bankAddress?.zip
    )

  const removeBankAccount = useCallback(
    () =>
      execRemoveBankAccount({
        variables: {
          input: {},
        },
      }),
    [execRemoveBankAccount],
  )

  const onPressRemoveBankAccount = () => {
    const confirmed = confirm(
      "Remove Bank Account\nAre you sure you want to remove this bank account?",
    )

    if (confirmed) {
      removeBankAccount()
    }
  }

  const hasNoBankAccount = !(bankAccount || error)

  useEffect(() => {
    if (!hasNoBankAccount && shouldRequireAddress === false) {
      onValidBankAccount?.()
    }
  }, [hasNoBankAccount, shouldRequireAddress])

  if (bankLoading || addressLoading || plaidLoading)
    return (
      <PageContainer>
        <LoadingScreen message="Loading..." />
      </PageContainer>
    )

  if (hasNoBankAccount) {
    return (
      <AddBankAccount
        onClose={() => {
          setRequireAddress(true)
        }}
      />
    )
  }

  if (forceEditAddress || !!showEditAddress) {
    return (
      <AddBillingAddress
        viewer={values.editViewer}
        onClose={(newViewer) => {
          setShowEditAddress(false)
          if (newViewer) {
            void setFieldValue(
              "editViewer",
              newViewer as GetPlaidAddressQuery["plaidIdentity"],
            )
          }
        }}
      />
    )
  }

  return (
    <PageContainer>
      <TitledParagrah
        title="Linked Bank Account"
        paragraph="All Transactions are secure and encrypted."
      />
      {!!bankAccount && (
        <View>
          <Card>
            <View>
              <BankAccountNameText>{bankAccount.name}</BankAccountNameText>
              <CardListItem>
                <CardListItemLabel>Institution</CardListItemLabel>

                <CardListItemValue>{bankAccount.bankName}</CardListItemValue>
              </CardListItem>
              <CardListItem>
                <CardListItemLabel>Account Type</CardListItemLabel>

                <CardListItemValue style={{ textTransform: "capitalize" }}>
                  {bankAccount.accountType}
                </CardListItemValue>
              </CardListItem>
              <CardListItem>
                <CardListItemLabel>Account Number</CardListItemLabel>
                <CardListItemValue>
                  •••••••••••• ({bankAccount.accountNumberMask})
                </CardListItemValue>
              </CardListItem>
            </View>
          </Card>
          <Spacer />
          <Card>
            <View>
              <BankAddressHeader>
                <View>
                  <BankAccountNameText
                    style={{ paddingTop: 0, paddingBottom: 0 }}
                  >
                    Billing Address
                  </BankAccountNameText>
                  {shouldRequireAddress && (
                    <BankAddressRequired>
                      Billing address is required
                    </BankAddressRequired>
                  )}
                </View>
                {shouldRequireAddress && (
                  <Button
                    buttonStyle="link"
                    onClick={() => {
                      setShowEditAddress(true)
                    }}
                  >
                    Edit
                  </Button>
                )}
              </BankAddressHeader>
              <CardListItem>
                <CardListItemLabel>Full Name</CardListItemLabel>

                {values.editViewer && (
                  <CardListItemValue>
                    {values.editViewer.billingFirstName}{" "}
                    {values.editViewer.billingLastName}
                  </CardListItemValue>
                )}
              </CardListItem>
              <CardListItem>
                <CardListItemLabel>Address</CardListItemLabel>

                {bankAddress && (
                  <CardListItemValue
                    style={{
                      textTransform: "capitalize",
                      flexWrap: "wrap",
                      maxWidth: "50%",
                    }}
                  >
                    {bankAddress?.streetAddress1} {bankAddress?.streetAddress2}{" "}
                    {bankAddress?.city} {bankAddress?.state?.name},{" "}
                    {bankAddress?.zip}
                  </CardListItemValue>
                )}
              </CardListItem>
            </View>
          </Card>
          <Spacer />

          {shouldRequireAddress && (
            <Button
              type="submit"
              buttonStyle="primary"
              onClick={() => handleSubmit()}
            >
              Save
            </Button>
          )}
          <RemoveBankAccountView>
            <Button buttonStyle="danger" onClick={onPressRemoveBankAccount}>
              Remove Bank Account
            </Button>
          </RemoveBankAccountView>
        </View>
      )}
    </PageContainer>
  )
}

export default PaymentMethod

const BankAccountNameText = styled.h2(({ theme }) => ({
  padding: theme.margins.md,
  fontSize: theme.fontSizes.md,
  fontWeight: "600",
  color: theme.colors.textPrimary,
  margin: 0,
}))

const RemoveBankAccountView = styled.div(({ theme }) => ({
  marginTop: theme.margins.lg,
  justifyContent: "center",
  flexDirection: "row",
}))

const BankAddressHeader = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  justifyContent: "space-between",
  paddingTop: theme.margins.md,
  paddingBottom: theme.margins.xs,
}))

const BankAddressRequired = styled.span(({ theme }) => ({
  fontSize: theme.fontSizes.xs,
  paddingLeft: theme.margins.md,
  paddingRight: theme.margins.md,
  color: theme.colors.textLight,
  marginTop: theme.margins.xs,
}))

const View = styled.div(() => ({}))

const Spacer = styled.div(({ theme }) => ({ height: theme.margins.lg }))

const PageContainer = styled.div(({ theme }) => ({
  paddingTop: theme.margins.lg,
}))
