import styled from "@emotion/styled"
import React, { useState } from "react"
import AddIcon from "./add.png"
import Modal from "./Modal"

export const InfoBlock = ({
  text,
  children,
}: {
  text?: string
  children: React.ReactNode
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      <InfoBlockButton onClick={() => setIsModalOpen(true)}>
        {text && <Text>{text}</Text>}
        <img src={AddIcon} style={{ width: 14, height: 14 }} alt="More info" />
      </InfoBlockButton>

      <Modal isOpen={isModalOpen} close={() => setIsModalOpen(false)}>
        {children}
      </Modal>
    </>
  )
}

const Text = styled.text(({ theme }) => ({
  marginRight: theme.margins.xxs,
}))

const InfoBlockButton = styled.button(({ theme }) => ({
  padding: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: 0,
  background: "transparent",
  color: theme.colors.brightPink,
}))
